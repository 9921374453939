import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    zga: null
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getVueMeta: state => state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setVueMeta ({ commit }, payload) {
      commit('mutateVueMeta', payload)
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateVueMeta (state, payload) {
      if (hasProp(payload, 'zga')) { Vue.set(state, 'zga', payload.zga) }
    }
  }
}
