import { hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'
import { gatewayGraphqlClient as client } from '../../graphql'
import { validateEmail } from '../../graphql/gateway/query/validate'
import { logError, metric } from '../../utils'

/**
 * Application - Vuex
 */
export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    active: false,
    email: {
      visible: false,
      values: []
    },
    emailCo: {
      visible: false,
      values: []
    }
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getVerify: state => state
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    async verifyEmail ({ state, commit, dispatch, rootGetters, getters }, { value, touched, valid, action, field }) {
      if (!rootGetters['features/getFeatures']?.['verify-email']?.enabled) {
        return valid
      }

      const existing = state?.[field]?.values?.find(item => item.value === value)
      if (existing) {
        await commit('mutateVerify', {
          type: 'email',
          visible: true
        })

        return existing?.valid
      }

      if (
        !touched ||
        !valid ||
        action === 'input'
      ) return valid

      await commit('mutateVerify', {
        active: true
      })

      await dispatch('messages/setStatusMessage', {
        group: 'application',
        name: 'verifyEmail',
        show: true
      }, { root: true })

      let isValid = valid
      let error

      try {
        let response = await client.query({
          query: validateEmail,
          variables: { email: value }
        })

        response = response?.data?.validateEmail
        isValid = response?.valid
      } catch (e) {
        metric('verify', { action: 'verify_email', status: 'error', vertical: Vue.prototype.VERTICAL })
        logError('verify/verifyEmail', error)
      } finally {
        await commit('mutateVerify', {
          type: field,
          active: false,
          visible: !isValid,
          values: [
            ...state[field].values,
            { value, valid: isValid }
          ]
        })

        await dispatch('loader/hideLoader', {}, { root: true })
      }

      return isValid
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async showVerify ({ commit }, { type }) {
      await commit('mutateVerify', {
        type,
        visible: true
      })

      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async hideVerify ({ commit }, { type }) {
      await commit('mutateVerify', {
        type,
        visible: false
      })

      return true
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateVerify (state, payload) {
      if (hasProp(payload, 'active')) { Vue.set(state, 'active', payload.active) }
      if (hasProp(payload, 'type') && hasProp(payload, 'visible')) { Vue.set(state[payload.type], 'visible', payload.visible) }
      if (hasProp(payload, 'type') && hasProp(payload, 'values')) { Vue.set(state[payload.type], 'values', payload.values) }
    }
  }
}
