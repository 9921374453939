import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export function createStore ({ vertical, site, brand, market, route, userAttributes, config }) {
  modules.router.state.vertical = vertical
  modules.router.state.site = site
  modules.router.state.brand = brand
  modules.router.state.market = market
  modules.router.state.locale = route.locale
  modules.router.state.route = route
  modules.userAttributes.state.attributes = userAttributes
  modules.translations.state.locale = route.locale
  modules.translations.state.default = config.locales?.default
  modules.translations.state.options = config.locales?.options
  modules.zmartaSession.state.slugs = route.slugs

  // don't include any sensitive cookies in the store
  if (modules.router.state.route) {
    modules.router.state.route.cookies = {
      access_uuid: modules?.router?.state?.route?.cookies?.access_uuid
    }
  }

  if (config) {
    modules.config.state = {
      env: config?.env,
      zgap: config?.zgap,
      constants: config?.constants,
      fragments: config?.fragments
    }
  }

  if (route.routes) {
    modules.router.state.routes = route.routes || []
  }

  return new Vuex.Store({
    strict: Vue.prototype.LOCAL,
    modules
  })
}
