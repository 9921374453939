import { ZmartaAnalytics } from '@ocp-zmarta/zmarta-analytics'
import {
  censorName,
  currency,
  getCookie,
  getGABrand,
  getUrlSearchParams,
  hasProp,
  isClient,
  lowercase,
  lowercaseFirstLetter,
  number,
  phone,
  rate,
  registrationNumber,
  roundDown,
  roundUp,
  sentryErrorHandler,
  splitByThousand,
  uppercaseFirstLetter
} from '@ocp-zmarta/zmarta-cl'
import ZmartaExperiments from '@ocp-zmarta/zmarta-experiments'
import Vue from 'vue'
import Meta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import VuexPersistence from 'vuex-persist'
import App from './App.vue'
import { createStore } from './store'
import { local, session, verticalSession, verticalTracking, zmartaSession } from './store/storage'

// vue config
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.debug = process.env.NODE_ENV !== 'production'

// global variables - less string literals in Vue components
Vue.prototype.PROJECT_NAME = process.env.NAME
Vue.prototype.BUILD_NAME = process.env.BUILD_NAME
Vue.prototype.BUILD_DATE = process.env.BUILD_DATE
Vue.prototype.BUILD_HASH = process.env.BUILD_HASH
Vue.prototype.LOCAL = process.env.LOCAL === true || process.env.LOCAL === 'true'

// global empty gif to reference to
Vue.prototype.LAZYIMAGE = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='

const filters = {
  censorName,
  currency,
  rate,
  lowercase,
  lowercaseFirstLetter,
  number,
  phone,
  roundDown,
  roundUp,
  uppercaseFirstLetter,
  splitByThousand,
  registrationNumber
}

Vue.prototype.FILTERS = filters

Object.keys(filters)
  .forEach(filter => {
    Vue.filter(filter, filters[filter])
  })

Vue.use(Meta)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  cancelable: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

export function createApp ({ vertical, site, brand, market, route = null, userAttributes = null, config = null }) {
  Vue.prototype.PRODUCTION = config?.env === 'production'
  Vue.prototype.DEVELOPMENT = config?.env === 'development'

  Vue.prototype.BRAND = brand
  Vue.prototype.ZMARTA = brand === 'zmarta'
  Vue.prototype.BROKER = brand === 'broker'
  Vue.prototype.WHITE_LABEL = !!route?.meta?.brokerName
  Vue.prototype.SHOW_BROKER_TERMS = !!route?.meta?.showBrokerTerms
  Vue.prototype.SHOW_BROKER_DIGITAL_COMMUNICATION = !!route?.meta?.showBrokerDigitalCommunication

  Vue.prototype.MARKET = market
  Vue.prototype.SWEDEN = market === 'se'
  Vue.prototype.NORWAY = market === 'no'
  Vue.prototype.FINLAND = market === 'fi'

  Vue.prototype.VERTICAL = vertical
  Vue.prototype.CL = vertical === 'cl'
  Vue.prototype.SME = vertical === 'sme'
  Vue.prototype.MORTGAGE = vertical === 'mortgage'
  Vue.prototype.ELECTRICITY = vertical === 'electricity'
  Vue.prototype.INSURANCE = vertical === 'insurance'
  Vue.prototype.EPI = vertical === 'epi'

  const zCid = getCookie('zCid')
  if (zCid) Vue.prototype.ZCID = zCid

  Object.keys(config.constants)
    .forEach(constant => {
      Vue.prototype[constant] = config.constants[constant]
    })

  const enableLogging = Vue.prototype.LOCAL || Vue.prototype.REVIEW || (route?.query && 'debug' in route.query)

  const zmartaExperiments = new ZmartaExperiments({
    brand,
    country: market,
    environment: config?.env,
    options: {
      gaEventOnActivate: false,
      hotjarRecordingOnActivate: true,
      enableLogging,
      userId: route?.userId,
      userAgent: route?.userAgent,
      visitedUrl: route?.visitedUrl
    }
  })

  // mock cid when in development
  const cid = config?.env === 'development' ? 'cid' : null

  const zmartaAnalytics = new ZmartaAnalytics({
    site,
    brand,
    market,
    environment: config.env,
    productFamily: route?.meta?.productFamily || Vue.prototype.GA?.PRODUCT_FAMILY,
    product: route?.meta?.product || Vue.prototype.GA?.PRODUCT,
    silence: !enableLogging,
    ...(cid && { cid })
  })

  const store = createStore({ vertical, site, brand, market, route, userAttributes, config })

  Vue.prototype.EVENT_BUS = new Vue()
  Vue.prototype.EXPERIMENTS = zmartaExperiments
  Vue.prototype.ZGA = zmartaAnalytics
  Vue.prototype.TESTCAFE = hasProp(route?.query, 'testcafe') || hasProp(getUrlSearchParams(), 'testcafe')
  Vue.prototype.REVIEW = hasProp(route?.query, 'review') || hasProp(getUrlSearchParams(), 'review')
  Vue.prototype.SITESPEED = hasProp(route?.query, 'sitespeed') || hasProp(getUrlSearchParams(), 'sitespeed')
  Vue.prototype.WEB_VITALS = hasProp(route?.query, 'web-vitals') || hasProp(getUrlSearchParams(), 'web-vitals')
  Vue.prototype.IMG_SRC = `/assets/${Vue.prototype.PROJECT_NAME}/images/${market}`

  const app = new Vue({
    store,
    async beforeCreate () {
      const vertical = this.$store.getters['router/getRoute']?.vertical
      const market = this.$store.getters['router/getRoute']?.market
      const form = (await import('./store/modules/form')).default

      if (vertical === 'cl' && !this.$store.hasModule('cl')) {
        form.state = (await import(`./store/modules/cl/form/${market}`)).default

        const module = (await import('./store/modules/cl')).default
        this.$store.registerModule('cl', module, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'sme' && !this.$store.hasModule('sme')) {
        form.state = (await import(`./store/modules/sme/form/${market}`)).default

        const module = (await import('./store/modules/sme')).default
        this.$store.registerModule('sme', module, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'mortgage' && !this.$store.hasModule('mortgage')) {
        form.state = (await import(`./store/modules/mortgage/form/${market}`)).default

        const module = (await import('./store/modules/mortgage')).default
        this.$store.registerModule('mortgage', module, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'electricity' && !this.$store.hasModule('electricity')) {
        form.state = (await import(`./store/modules/electricity/form/${market}`)).default

        const module = (await import('./store/modules/electricity')).default
        this.$store.registerModule('electricity', module, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'insurance' && !this.$store.hasModule('insurance')) {
        form.state = (await import(`./store/modules/insurance/form/${market}`)).default

        const module = (await import('./store/modules/insurance')).default
        this.$store.registerModule('insurance', module, isClient() ? { preserveState: true } : {})
      } else if (vertical === 'epi' && !this.$store.hasModule('epi')) {
        form.state = (await import(`./store/modules/epi/form/${market}`)).default

        const module = (await import('./store/modules/epi')).default
        this.$store.registerModule('epi', module, isClient() ? { preserveState: true } : {})
      }

      this.$store.registerModule('form', form, isClient() ? { preserveState: true } : {})

      let { pageName, pageType, level } = route?.meta?.ga || {}
      pageName = pageName?.replace('{brand}', getGABrand({ brand, market }))

      const initialPageView = pageName && {
        pageName,
        cg1: level,
        cg2: route?.meta?.productFamily || Vue.prototype.GA?.PRODUCT_FAMILY,
        cg4: route?.meta?.product || Vue.prototype.GA?.PRODUCT,
        cg5: pageType,
        uid: route?.cookies?.access_uuid || ''
      }

      const zga = await zmartaAnalytics.init(initialPageView)
      await this.$store.dispatch('vueMeta/setVueMeta', { zga })

      if (isClient()) {
        // create storage plugins
        new VuexPersistence(local(vertical)).plugin(store)
        new VuexPersistence(session(vertical)).plugin(store)
        new VuexPersistence(verticalTracking(vertical)).plugin(store)
        new VuexPersistence(verticalSession(vertical)).plugin(store)
        new VuexPersistence(zmartaSession()).plugin(store)

        app.$mount(`#${this.PROJECT_NAME}`, true)
      }
    },
    errorCaptured (err, vm, info) {
      sentryErrorHandler(err, vm, info, this.PROJECT_NAME)
    },
    render: h => h(App)
  })

  return { app, store }
}
