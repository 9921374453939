import { gql } from '@apollo/client/core'

export const validateEmail = gql`
    query validateEmail ($email: String!) {
        validateEmail(email: $email) {
            email
            valid
        }
    }
`
